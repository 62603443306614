<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                    <li class="active a">订单查询</li>
             
                    <li class=" a"><router-link to="/index/tsdd">投诉订单</router-link></li>
                </div>
                 <div  class="item">  
      <li class="a" ><router-link to="/index/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                      <li style="cursor:pointer;" @click="dialogFormVisible = true">总台控制<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
     
            <div class="content">
                    <p style="margin:20px 0">订单列表</p>


                     <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
    <el-tab-pane label="全部" name="first">

          <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input2">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input3">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input4">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
           <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                  fixed
                    prop="orderid"
                    label="订单编号"
                    width="100">
                    </el-table-column>


                   <el-table-column
                     
                        prop="ordertype" 
                    label="订单类型" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
                   

                    
                    <el-table-column
                        prop="name" 
                    label="订单姓名" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                    <el-table-column
                      
                    label="邀请人"
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                        <p  :class="{red:scope.row.yqr=='超级管理员'}">{{scope.row.yqr | name}}</p>                    
                        </template>
                    </el-table-column>
                         <el-table-column
                        prop="xzqh" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
               <el-table-column
                        prop="address" 
                    label="地址"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="starttime" 
                    label="预约时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
       <el-table-column
                      
                    label="订单状态"
                        :show-overflow-tooltip="true" 
                   
                    >
                    <template slot-scope="scope" >
                        <p>{{scope.row.status | status1}}</p>                    
                        </template>
                    </el-table-column>
 <el-table-column
                        prop="fee" 
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope" >
       <p > {{scope.row.fee/100}}</p>
      </template>
                    </el-table-column>


  
    
                        <el-table-column
                        prop="dls" 
                    label="所属代理商"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    
                        
           <el-table-column
                        prop="tls" 
                    label="调律师"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    <!-- <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">查看详情</el-button>
                         
                        </template>
                        </el-table-column> -->
                </el-table>

 <el-pagination
      @size-change="handleSizeChange8"
      @current-change="handleCurrentChange8"
      :current-page="pagination9.page_index"
      :page-sizes="pagination9.page_sizes"
      :page-size="pagination9.page_size"
      :layout="pagination9.layout"
      :total='pagination9.total'
      style="margin-top:20px">
  </el-pagination>

    </el-tab-pane>
    <el-tab-pane label="待确认" name="second">  
            <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input22">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input11">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input33">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input44">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value3"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck11">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck22">查询</p>
            </div>
        </div>
        
          <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="orderid"
                    label="订单编号"
                    width="100">
                    </el-table-column>


                    <el-table-column
                        prop="ordertype" 
                    label="订单类型" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
                   

                    
                    <el-table-column
                        prop="name" 
                    label="订单姓名" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                     <el-table-column
                      
                    label="邀请人"
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                        <p  :class="{red:scope.row.yqr=='超级管理员'}">{{scope.row.yqr | name}}</p>                    
                        </template>
                    </el-table-column>
                         <el-table-column
                        prop="xzqh" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
               <el-table-column
                        prop="address" 
                    label="地址"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="starttime" 
                    label="预约时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
 <el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
   <el-table-column
                        prop="comment" 
                    label="留言"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
 <el-table-column
                        prop="fee" 
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope" >
       <p > {{scope.row.fee/100}}</p>
      </template>
                    </el-table-column>


   
    
                        <el-table-column
                        prop="dls" 
                    label="所属代理商"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    
                        
           <el-table-column
                        prop="tls" 
                    label="处理"
                        :show-overflow-tooltip="true" 
                      
                    >
                    <template slot-scope="scope" >
      <el-button
                        size="mini"
                            type="warning"
                          @click="chuli(scope.$index, scope.row)"
                     >处理</el-button>
                   

      </template>
                    </el-table-column>
                    <!-- <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">查看详情</el-button>
                         
                        </template>
                        </el-table-column> -->
                </el-table>
                 <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="pagination1.page_index"
      :page-sizes="pagination1.page_sizes"
      :page-size="pagination1.page_size"
      :layout="pagination1.layout"
      :total='pagination1.total'
      style="margin-top:20px">
                 </el-pagination>
                </el-tab-pane>
    <el-tab-pane label="完成支付" name="third">   
            <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input222">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input111">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input333">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input444">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value4"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck111">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck222">查询</p>
            </div>
        </div>
         <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="orderid"
                    label="订单编号"
                    width="100">
                    </el-table-column>


                 <el-table-column
                        prop="ordertype" 
                    label="订单类型" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
                   

                    
                    <el-table-column
                        prop="name" 
                    label="订单姓名" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                       <el-table-column
                      
                    label="邀请人"
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                        <p  :class="{red:scope.row.yqr=='超级管理员'}">{{scope.row.yqr | name}}</p>                    
                        </template>
                    </el-table-column>
                         <el-table-column
                        prop="xzqh" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
               <el-table-column
                        prop="address" 
                    label="地址"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="starttime" 
                    label="预约时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>

<el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
 <el-table-column
                        prop="fee" 
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope" >
       <p > {{scope.row.fee/100}}</p>
      </template>
                    </el-table-column>


  
    
                        <el-table-column
                        prop="dls" 
                    label="所属代理商"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    
                        
           <el-table-column
                        prop="tls" 
                    label="调律师"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    <!-- <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">查看详情</el-button>
                         
                        </template>
                        </el-table-column> -->
                </el-table>
                  <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="pagination3.page_index"
      :page-sizes="pagination3.page_sizes"
      :page-size="pagination3.page_size"
      :layout="pagination3.layout"
      :total='pagination3.total'
      style="margin-top:20px">
    </el-pagination>
                
                </el-tab-pane>
    <el-tab-pane label="待接单" name="four">  
            <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input2222">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1111">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input3333">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input4444">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value5"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1111">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2222">查询</p>
            </div>
        </div>
          <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="orderid"
                    label="订单编号"
                    width="100">
                    </el-table-column>


                     <el-table-column
                        prop="ordertype" 
                    label="订单类型" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
                   

                    
                    <el-table-column
                        prop="name" 
                    label="订单姓名" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                     <el-table-column
                      
                    label="邀请人"
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                        <p  :class="{red:scope.row.yqr=='超级管理员'}">{{scope.row.yqr | name}}</p>                    
                        </template>
                    </el-table-column>
                         <el-table-column
                        prop="xzqh" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
               <el-table-column
                        prop="address" 
                    label="地址"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="starttime" 
                    label="预约时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
<el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
   
 <el-table-column
                        prop="fee" 
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope" >
       <p > {{scope.row.fee/100}}</p>
      </template>
                    </el-table-column>


  
    
                        <el-table-column
                        prop="dls" 
                    label="所属代理商"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    
                        
           <el-table-column
                        prop="tls" 
                    label="调律师"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    <!-- <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">查看详情</el-button>
                         
                        </template>
                        </el-table-column> -->
                </el-table>
                   <el-pagination
      @size-change="handleSizeChange3"
      @current-change="handleCurrentChange3"
      :current-page="pagination4.page_index"
      :page-sizes="pagination4.page_sizes"
      :page-size="pagination4.page_size"
      :layout="pagination4.layout"
      :total='pagination4.total'
      style="margin-top:20px">
    </el-pagination>
                </el-tab-pane>
  

                 <el-tab-pane label="服务完成" name="six">   
             <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex; flex-wrap: wrap;width:100%">
             
               <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">订单ID</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input222222">
                </el-input>
            </div>
                 <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">姓名</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input111111">
                </el-input>
            </div>
          
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;">
                  <span style="margin-right:5px">调律师工号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input333333">
                </el-input>
            </div>
             <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 25%;
      min-width: 25%;
       max-width: 25%;
       margin-top:20px">
                  <span style="margin-right:5px">手机号</span>  
                  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input444444">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px;  flex: 1;
       width: 50%;
      min-width: 50%;
       max-width: 50%;
       margin-top:20px">
        <span style="margin-right:5px">时间选择</span>  
                 <el-date-picker
      v-model="value7"
      type="daterange"
      align="right"
         value-format='yyyy-MM-dd'
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
    </el-date-picker>
            </div>
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck111111">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck222222">查询</p>
            </div>
        </div>
          <el-table
                    :border="true"
                    :data="data1"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="orderid"
                    label="订单编号"
                    width="100">
                    </el-table-column>


                   <el-table-column
                        prop="ordertype" 
                    label="订单类型" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>
                   

                    
                    <el-table-column
                        prop="name" 
                    label="订单姓名" 
                        :show-overflow-tooltip="true" 
                   >
                
                    </el-table-column>

                        <el-table-column
                        prop="phone" 
                    label="手机号"
                        :show-overflow-tooltip="true" 
                      
                    >
                    </el-table-column>
                     <el-table-column
                      
                    label="邀请人"
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                        <p  :class="{red:scope.row.yqr=='超级管理员'}">{{scope.row.yqr | name}}</p>                    
                        </template>
                    </el-table-column>
                         <el-table-column
                        prop="xzqh" 
                    label="用户地区"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
               <el-table-column
                        prop="address" 
                    label="地址"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
  <el-table-column
                        prop="starttime" 
                    label="预约时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
<el-table-column
                        prop="createtime" 
                    label="下单时间"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
    
 <el-table-column
                        prop="fee" 
                    label="金额"
                        :show-overflow-tooltip="true" 
                      
                    >
                      <template slot-scope="scope" >
       <p > {{scope.row.fee/100}}</p>
      </template>
                    </el-table-column>

   
    
                        <el-table-column
                        prop="dls" 
                    label="所属代理商"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    
                        
           <el-table-column
                        prop="tls" 
                    label="调律师"
                        :show-overflow-tooltip="true" 
                      
                    ></el-table-column>
                    <!-- <el-table-column
                        fixed="right"
                        label="操作"
                      >
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="success" size="small">查看详情</el-button>
                         
                        </template>
                        </el-table-column> -->
                </el-table>
                     <el-pagination
      @size-change="handleSizeChange4"
      @current-change="handleCurrentChange4"
      :current-page="pagination5.page_index"
      :page-sizes="pagination5.page_sizes"
      :page-size="pagination5.page_size"
      :layout="pagination5.layout"
      :total='pagination5.total'
      style="margin-top:20px">
    </el-pagination>
                </el-tab-pane>
  </el-tabs>
       
            </div>
  </div>
</template>

<script>
export default {
        filters: {
            status1:function(value){
              if(value == 0){
                return '待确认'
              }else if(value==2){
               return '支付完成'
              }else if(value==9){
               return '待接单'
              }else if(value==8){
               return '待服务'
              }else if(value==1){
               return '待支付'
              }else if(value==4){
               return '订单完成'
              }
             
            },
  capitalize: function (value) {
  if(value==0){
      return '正常'
     
  }else {
      return '附加订单'
  }
  },
   capitalize1: function (value) {
  if(value==0){
      return '未使用'
    
  }else if(value == 1) {
      return '使用'
  }
  },
  name:function(value){
        if(value=='超级管理员'){
      return '平台推送'
      
  }else{
      return value
  }
  }
},
 data(){
        return{
             pagination9:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            color:'',
            form4:{},     value:'',
            dialogFormVisible4:false,
             restaurants: [],
              pagination:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination1:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },

               pagination3:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
               pagination4:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination5:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            result:'',
              pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      
        value2: '',
    value3: '',
    value4:'',
      value5:'',
        value6:'',
            value7:'',
          input1111:'',
            input2222:'',
             input3333:'',
            input4444:'',
              input11111:'',
            input22222:'',
             input33333:'',
            input44444:'',
             input111111:'',
            input222222:'',
             input333333:'',
            input444444:'',
      input111:'',
            input222:'',
             input333:'',
            input444:'',
            input1:'',
            input2:'',
             input3:'',
            input4:'',
              input11:'',
            input22:'',
             input33:'',
            input44:'',
             activeName: 'first',
            datal1:[],
            datall:[],
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],

           orderid:'',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       methods:{

           chuli(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/modify_order_status",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
      
                                "orderid": row.orderid
                            }
                        }) .then(res=>{
                                if(res.data.code==0){
                                       this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:0,
      
         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination1.total =res.data.count
      
        })
        .catch(function(){
           
        })
                                }else{
                                      this.$message({
            type: 'error',
            message: res.data.msg
          });
                                }

                        }) 
           },
             handleSizeChange8(val) {
this.pagination9.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination9.page_index,
       limit :val,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination9.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange8(val) {
       this.pagination9.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination9.page_size,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination9.total=res.data.count
        })
        .catch(function(){
           
        })

  
        
      },

    tianjia4(){
     
        this.$axios({
                        method: "post",
                        url: "/s/backend/api/point_tls",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
          "jobnumber":this.value,
         "orderid": this.orderid
      }
                        }) .then(res=>{
                              this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:0,
        jobnumber_1: localStorage.getItem('jobnumber') 

         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination1.total =res.data.count
            this.dialogFormVisible4 =false
            this.dialogFormVisible=false
        })
        .catch(function(){
           
        })
                        }).catch({

                            
                        })


    },
 
     handleEdit14(index,row){
         this.orderid = row.orderid
         this.jobnumber= row.jobnumber
   this.dialogFormVisible4 = true
     },
              handleSizeChange(val) {
this.pagination.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination.page_index,
       limit :val,
      }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange(val) {
       this.pagination.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination.page_size,
       roleid :10  }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination.total =res.data.count
        })
        .catch(function(){
           
        })

  
        
      },


        handleSizeChange1(val) {
this.pagination1.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination1.page_index,
       limit :val,
       roleid :10,
       status: 0
    }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination1.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange1(val) {
       this.pagination1.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination1.page_size,
       roleid :10 ,
       status: 0
       }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination1.total=res.data.count
        })
        .catch(function(){
           
        })

  
        
      },


         handleSizeChange2(val) {
this.pagination3.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination3.page_index,
       limit :val,
       roleid :10,status: 2
        }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination3.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange2(val) {
       this.pagination3.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination3.page_size,
       roleid :10 ,status: 2
         }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination3.total=res.data.count
        })
        .catch(function(){
           
        })

  
        
      },


         handleSizeChange3(val) {
this.pagination4.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination4.page_index,
       limit :val,
       roleid :10 ,
       status: 9 }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination4.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange3(val) {
       this.pagination4.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination4.page_size,
       roleid :10 ,
       status: 9 }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination4.total=res.data.count
        })
        .catch(function(){
           
        })

  
        
      },


     handleSizeChange4(val) {
this.pagination5.page_size=val


    
 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination5.page_index,
       limit :val,
       roleid :10 ,
       status: 4 }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination5.total =res.data.count
        })
        .catch(function(){
           
        })


            
                 
      },
      handleCurrentChange4(val) {
       this.pagination4.page_index=val
     

 this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
      page :val,
      limit :this.pagination5.page_size,
       roleid :10 ,
       status: 4 }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination5.total=res.data.count
        })
        .catch(function(){
           
        })

  
        
      },


            ck1(){
          
this.input1=''
this.input2=''
this.input3=''
this.input4=''
this.value2 = ''
   this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              
}
})
  .then(res=>{
         
           this.data1= res.data.data
        })
        .catch(function(){
           
        })
        },
        ck2(){
              this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
                orderid:this.input2,
                phone:this.input4,
                name:this.input1,
                tls:this.input3,
                starttime:this.value2[0],
                endtime:this.value2[1]
}
})
  .then(res=>{
         
           this.data1= res.data.data
        })
        .catch(function(){
           
        })
         
        },

  ck11(){
          
this.input11=''
this.input22=''
this.input33=''
this.input44=''
this.value3 = ''

    this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              
}
})
  .then(res=>{
         
            var arr = []
                     for(var i = 0;i<res.data.data.length;i++){
                         if(res.data.data[i].status==0){
                           arr.push(res.data.data[i])
                         }
                     }
                     this.data1 = arr
        })
        .catch(function(){
           
        })

   
        },
        ck22(){
            
        
                  this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :10,
                roleid :10 ,
                orderid:this.input22,
                phone:this.input44,
                name:this.input11,
                tls:this.input33,
                starttime:this.value3[0],
                endtime:this.value3[1],
                status: 0
}
})
  .then(res=>{
         
        
                     this.data1 = res.data.data
        })
        .catch(function(){
           
        })
        },


 ck111(){
          
this.input111=''
this.input222=''
this.input333=''
this.input444=''
this.value4 = ''

    this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              status: 2
}
})
  .then(res=>{
         
   
                     this.data1 = res.data.data
        })
        .catch(function(){
           
        })

   
        },
        ck222(){
            
           
                  this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
                orderid:this.input222,
                phone:this.input444,
                name:this.input111,
                tls:this.input333,
                starttime:this.value4[0],
                endtime:this.value4[1],
                status: 2
}
})
  .then(res=>{
         
            this.data1 = res.data.data
        })
        .catch(function(){
           
        })
        },


 ck1111(){
          
this.input1111=''
this.input2222=''
this.input3333=''
this.input4444=''
this.value5 = ''

    this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              status: 9
}
})
  .then(res=>{
         
            var arr = []
                     for(var i = 0;i<res.data.data.length;i++){
                         if(res.data.data[i].status==2){
                           arr.push(res.data.data[i])
                         }
                     }
                     this.data1 = arr
        })
        .catch(function(){
           
        })

   
        },
        ck2222(){
            
           
                  this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
                orderid:this.input2222,
                phone:this.input4444,
                name:this.input1111,
                tls:this.input3333,
                starttime:this.value5[0],
                endtime:this.value5[1],
                status: 9
}
})
  .then(res=>{
         
           this.data1 = res.data.data
        })
        .catch(function(){
           
        })
        },


 ck11111(){
          
this.input11111=''
this.input22222=''
this.input33333=''
this.input44444=''
this.value6 = ''

    this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              status: 8
}
})
  .then(res=>{
         
            var arr = []
                     for(var i = 0;i<res.data.data.length;i++){
                         if(res.data.data[i].status==4){
                           arr.push(res.data.data[i])
                         }
                     }
                     this.data1 = arr
        })
        .catch(function(){
           
        })

   
        },
        ck22222(){
            
           
                  this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
                orderid:this.input22222,
                phone:this.input44444,
                name:this.input11111,
                tls:this.input33333,
                starttime:this.value6[0],
                endtime:this.value6[1],
                status: 8
}
})
  .then(res=>{
         
           this.data1 = res.data.data
        })
        .catch(function(){
           
        })
        },



         ck111111(){
          
this.input111111=''
this.input222222=''
this.input333333=''
this.input444444=''
this.value6 = ''

    this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
              status:4
}
})
  .then(res=>{
         
            var arr = []
                     for(var i = 0;i<res.data.data.length;i++){
                         if(res.data.data[i].status==4){
                           arr.push(res.data.data[i])
                         }
                     }
                     this.data1 = arr
        })
        .catch(function(){
           
        })

   
        },
        ck222222(){
            
           
                  this.$axios.get("/s/backend/api/order_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
              page :1,
                limit :20,
                roleid :10 ,
                orderid:this.input222222,
                phone:this.input444444,
                name:this.input111111,
                tls:this.input333333,
                starttime:this.value7[0],
                endtime:this.value7[1],
                status: 4
}
})
  .then(res=>{
         
           this.data1 = res.data.data
        })
        .catch(function(){
           
        })
        },

             handleClick(tab, event) {
               if(tab.name == 'first'){
                     this.$axios.get("/s/backend/api/order_list", {
                    params: { 'accessToken':localStorage.getItem('token'),
                page :1,
                limit :20,
                roleid :10  }
                    })

                .then(res=>{
                
                    this.data1 = res.data.data
            
                    })
                    .catch(function(){
                    
                    })
  
               }else if(tab.name == 'second'){
                     this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:0,
      
         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination1.total =res.data.count
      
        })
        .catch(function(){
           
        })
                 
                    
               }else if(tab.name == 'third'){
                              this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:2,

         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination3.total =res.data.count
    
        })
        .catch(function(){
           
        })
               }else  if(tab.name == 'four'){
                           this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:9,
       
         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination4.total =res.data.count
      
        })
        .catch(function(){
           
        })
                     }else if (tab.name == 'five'){
   this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:8,
      
         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination2.total =res.data.count
      
        })
        .catch(function(){
           
        })
                     }else if (tab.name == 'six'){
   this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
       status:4,
     
         }
        })

     .then(res=>{
    
          this.data1 = res.data.data
            this.pagination5.total =res.data.count
      
        })
        .catch(function(){
           
        })
                     }
      },
 
            a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
             tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
       },
        mounted(){
            this.$axios.get("/s/backend/api/user_list", {
                        params: { 'accessToken':localStorage.getItem('token'),
                    'jobnumber_1':localStorage.getItem('jobnumber'),
                        'limit':10,
                        'page':1,
                        'roleid':6,
                    
                     }
                            })

     .then(res=>{
    
 
      this.restaurants=res.data.data
 
        })
        .catch(function(){
           
        })
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })
  
 




   this.$axios.get("/s/backend/api/order_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :10,
      }
        })

     .then(res=>{
    
         this.data1 = res.data.data
            this.pagination9.total =res.data.count
        })
        .catch(function(){
           
        })

  
    
         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red !important;
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}
/deep/ .el-dialog{
        width:35%;

 
  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

    /deep/.el-tabs__header{
        margin:0
    }

    /deep/.el-tabs__item.is-active{
        background: #0034FF;
        color:#fff;
    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>